.navbar .nav > li > a {
  color: #111;
  text-shadow: none;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .card {
    flex: 0 0 auto;
  }
}

.box-gray{
  height: 450px;
}